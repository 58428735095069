import Link from "next/link";

import { useState } from "react";

import MenuData from "../../data/MegaMenu";


const Nav = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const toggleMenuItem = (item) => {
    setActiveMenuItem(activeMenuItem === item ? null : item);
  };

  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
       
     
        <li>
          <Link
            className={`${activeMenuItem === "home" ? "open" : ""}`}
            href="/"
            onClick={() => toggleMenuItem("home")}
          >
            Home
          </Link>
        </li>
    
     
        <li className="has-dropdown has-menu-child-item">
          <Link
            className={`${activeMenuItem === "about" ? "open" : ""}`}
            href="#"
            onClick={() => toggleMenuItem("about")}
          >
            About
            <i className="feather-chevron-down"></i>
          </Link>
          <ul
            className={`submenu ${
              activeMenuItem === "about" ? "active d-block" : ""
            }`}
          >
            {MenuData &&
              MenuData.menuData.map((data, index) => {
                if (data.menuType === "about") {
                  const elements = data.menuItems?.map((value, innerIndex) => (
                    <li key={innerIndex}>
                      <Link href={value.link ?? '#'}>{value.title}</Link>
                    </li>
                  ));
                  return elements;
                }
                return null;
              })}
          </ul>
        </li>
        
        <li className="has-dropdown has-menu-child-item">
          <Link
            className={`${activeMenuItem === "academics" ? "open" : ""}`}
            href="#"
            onClick={() => toggleMenuItem("academics")}
          >
            Academics
            <i className="feather-chevron-down"></i>
          </Link>
          <ul
            className={`submenu ${
              activeMenuItem === "academics" ? "active d-block" : ""
            }`}
          >
            {MenuData &&
              MenuData.menuData.map((data, index) => {
                if (data.menuType === "academics") {
                  const elements = data.menuItems?.map((value, innerIndex) => (
                    <li key={innerIndex}>
                      <Link href={value.link ?? '#'}>{value.title}</Link>
                    </li>
                  ));
                  return elements;
                }
                return null;
              })}
          </ul>
        </li>
        
        <li className="has-dropdown has-menu-child-item">
          <Link
            className={`${activeMenuItem === "admission" ? "open" : ""}`}
            href="#"
            onClick={() => toggleMenuItem("admission")}
          >
            Rules & Regulations
            <i className="feather-chevron-down"></i>
          </Link>
          <ul
            className={`submenu ${
              activeMenuItem === "admission" ? "active d-block" : ""
            }`}
          >
            {MenuData &&
              MenuData.menuData.map((data, index) => {
                if (data.menuType === "admission") {
                  const elements = data.menuItems?.map((value, innerIndex) => (
                    <li key={innerIndex}>
                      <Link href={value.link ?? '#'}>{value.title}</Link>
                    </li>
                  ));
                  return elements;
                }
                return null;
              })}
          </ul>
        </li>


        {/* <li className="has-dropdown has-menu-child-item">
          <Link
            className={`${activeMenuItem === "facilities" ? "open" : ""}`}
            href="#"
            onClick={() => toggleMenuItem("facilities")}
          >
            Facilities
            <i className="feather-chevron-down"></i>
          </Link>
          <ul
            className={`submenu ${
              activeMenuItem === "facilities" ? "active d-block" : ""
            }`}
          >
            {MenuData &&
              MenuData.menuData.map((data, index) => {
                if (data.menuType === "facilities") {
                  const elements = data.menuItems?.map((value, innerIndex) => (
                    <li key={innerIndex}>
                      <Link href={value.link ?? '#'}>{value.title}</Link>
                    </li>
                  ));
                  return elements;
                }
                return null;
              })}
          </ul>
        </li> */}

        <li>
          <Link
            className={`${activeMenuItem === "contact-us" ? "open" : ""}`}
            href="/contact"
            onClick={() => toggleMenuItem("contact-us")}
          > 
            Contact Us
          </Link>
        </li>
    
      </ul>
    </nav>
  );
};
export default Nav;
